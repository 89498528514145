export default {
    getScales: [
        {
            label: '1-30人',
            value: '1'
        },
        {
            label: '30-60人',
            value: '2'
        },
        {
            label: '60-100人',
            value: '3'
        }
    ],
    getIndustrys: [
        {
            label: '互联网',
            value: '1'
        },
        {
            label: '零售',
            value: '2'
        },
        {
            label: '地摊',
            value: '3'
        }
    ]
}
