<template>
    <div class="wrapper">
        <div class="top">
            <el-form :model="createEnterpriseInformationFrom"
                     :rules="rules"
                     ref="createEnterpriseInformationFrom"
            >
                <el-form-item class="cu-form-item">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.improveEnterpriseInformation')}}</span>
                </el-form-item>
                <el-form-item class="cu-form-item" :label="$t('login.merchantNameCompanyName') + ':'" prop="name">
                    <el-input class="input" v-model="createEnterpriseInformationFrom.name" type="text" :placeholder="$t('login.pleaseMerchantNameCompanyName')"/>
                </el-form-item>
                <el-form-item class="cu-form-item" :label="$t('login.scaleCompany') + ':'" prop="scale">
                    <el-select  class="input" v-model="createEnterpriseInformationFrom.scale" :placeholder="$t('login.pleaseSelect')">
                        <el-option
                                v-for="item in scales"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="cu-form-item" :label=" $t('login.industryInvolved') + ':'" prop="industry">
                    <el-select  class="input" v-model="createEnterpriseInformationFrom.industry" :placeholder="$t('login.pleaseSelect')">
                        <el-option
                                v-for="item in industrys"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="cu-form-item" :label="$t('login.contactNumber')" prop="phone">
                    <el-input class="input" v-model="createEnterpriseInformationFrom.phone" type="text" :placeholder="$t('login.pleaseEnterContactNumber')"/>
                </el-form-item>
                <el-form-item class="cu-form-item" :label="$t('login.email') + ':'"  prop="email">
                    <el-input class="input" type="text" v-model="createEnterpriseInformationFrom.email" :placeholder="$t('login.pleaseEnterEmailAddress')"/>
                </el-form-item>
                <el-form-item class="cu-form-item" :label="$t('login.detailedAddressMerchant') + ':'"  prop="address">
                    <el-input class="input" type="text" v-model="createEnterpriseInformationFrom.address" :placeholder="$t('login.pleaseEnterMerchantDetailedAddress')"/>
                </el-form-item>
                <el-form-item class="cu-form-item" style="margin-top: 20px">
                    <el-button style="line-height: 10px;" @click="()=>{
                                this.$router.back();
                            }">{{$t('login.lastStep')}}</el-button>
                    <el-button style="width: 200px; line-height: 10px;" type="primary" @click="addTblMerchant" :disabled="disabled">{{$t('login.enteringWorkspace')}}</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {addTblMerchant, updateLastEntryTime} from "../../../request/api/merchant";
    import DictTool from "../../../utils/DictTool";

    export default {
        name: "CreateEnterpriseInformation",
        data(){
            return {
                createEnterpriseInformationFrom: {
                    name: '',
                    phone: '',
                    email: '',
                    address: '',
                    scale: '',
                    industry: ''
                },
                scales: DictTool.getScales,
                industrys: DictTool.getIndustrys,
                disabled: false,
                rules: {
                    name: [
                        { required: true, message: this.$t('login.pleaseEnterMerchantNameCompanyName'), trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: this.$t('login.pleaseEnterCellPhoneNumber'), trigger: 'blur' },
                        { pattern: "^(?:0|86|\\+86)?1[3-9]\\d{9}$", message: this.$t('login.pleaseInputCorrectMobilePhoneNumber') }
                    ],
                    email: [
                        { required: true, message: this.$t('login.pleaseEnterEmailAddress'), trigger: 'blur' },
                        { pattern: "^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])$", message: this.$t('login.pleaseEnterCorrectEmailAddress') }
                    ],
                    scale: [
                        { required: true, message: this.$t('login.pleaseEnterCompanySize'), trigger: 'blur' },
                    ],
                    industry: [
                        { required: true, message: this.$t('login.pleaseEnterIndustryCompany'), trigger: 'blur' },
                    ],
                    address: [
                        { required: true, message: this.$t('login.pleaseEnterMerchantDetailedAddress'), trigger: 'blur' },
                    ]
                }
            }
        },
        methods: {
            setMerchant(item) {
                let _this = this;
                _this.updateLastEntryTime(item.merchantId, function () {
                    _this.$MERCHANT(item);
                    _this.$router.replace("/edit/create");
                })
            },
            updateLastEntryTime(merchantId, fallback) {
                updateLastEntryTime({
                    merchantId: merchantId
                }).then(result => {
                    if (result.code === 200){
                        fallback();
                    }else {
                        console.error(result)
                    }
                })
            },
            addTblMerchant() {
                let _this = this;
                _this.disabled = true;
                this.$refs["createEnterpriseInformationFrom"].validate((val) => {
                    if (val){
                        let data = {
                            "name": this.createEnterpriseInformationFrom.name,
                            "phone": this.createEnterpriseInformationFrom.phone,
                            "email": this.createEnterpriseInformationFrom.email,
                            "address": this.createEnterpriseInformationFrom.address,
                            "industry": this.createEnterpriseInformationFrom.industry,
                            "scale": this.createEnterpriseInformationFrom.scale
                        };
                        addTblMerchant(data).then((result) => {
                            if (result.code === 200){
                                _this.disabled = true;
                                if (result.code === 200){
                                    _this.setMerchant({
                                        merchantId: result.data.id,
                                        merchantName: result.data.name,
                                    })
                                }else {
                                    _this.$notify({
                                        title: _this.$t('login.hint'),
                                        message: _this.$t('login.error'),
                                        type: 'warning'
                                    });
                                }
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                                _this.disabled = false;
                            }
                        })
                    }else {
                        _this.disabled = false;
                        console.error("submit error");
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index.less";
    @input-width: 308px;

    .cu-form-item {
        margin-bottom: 5px;
    }

    .input {
        width: 308px;
        height: 32px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .top {
            width: @input-width;
            height: 550px;
            padding: 35px;

            .avatar-uploader {
                border: 1px dashed #d9d9d9;
                width: 100px;
                height: 100px;
                border-radius: 10px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                margin-top: 10px;
            }
            .avatar-uploader:hover {
                border-color: #409EFF;
            }
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
            }
            .avatar {
                width: 100%;
                height: 100%;
                display: block;
            }
        }
    }
</style>
